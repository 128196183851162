import { PENDING, SUCCESS, FAIL } from '../../reduxPromise'
import _ from 'lodash'

class DrugsReducers {
  searchDrugs (state, action) {
    switch (action.status) {
      case PENDING:
        return {
          ...state,
          status: PENDING,
          action: action.type,
          findAllDrugsOnly: false
        }
      case SUCCESS:
        return {
          ...state,
          status: SUCCESS,
          action: undefined,
          count: _.get(action, 'result.count', 0),
          results: _.get(action, 'result.rows', []),
          error: {},
          findAllDrugsOnly: false
        }
      case FAIL:
        return {
          ...state,
          status: FAIL,
          count: 0,
          results: [],
          error: action.error,
          findAllDrugsOnly: false
        }
      default:
        return state
    }
  }

  searchAllDrugs (state, action) {
    switch (action.status) {
      case PENDING:
        return {
          ...state,
          status: PENDING,
          action: action.type,
          findAllDrugsOnly: false
        }
      case SUCCESS:
        return {
          ...state,
          status: SUCCESS,
          action: undefined,
          count: _.get(action, 'result.count', 0),
          results: _.get(action, 'result.rows', []),
          error: {},
          findAllDrugsOnly: true
        }
      case FAIL:
        return {
          ...state,
          status: FAIL,
          count: 0,
          results: [],
          error: action.error,
          findAllDrugsOnly: false
        }
      default:
        return state
    }
  }

  fetchDrug (state, action) {
    switch (action.status) {
      case PENDING:
        return {
          ...state,
          status: PENDING,
          action: action.type
        }
      case SUCCESS:
        return {
          ...state,
          currentDrug: _.get(action, 'result', []),
          status: SUCCESS
        }
      case FAIL:
        return {
          ...state,
          status: FAIL,
          error: action.error
        }
      default:
        return state
    }
  }
}

export default new DrugsReducers()
