import * as constants from './constants'
import reducers from './reducers'

const defaultState = {
  size: 10,
  page: 1,
  language: undefined,
  error: undefined,
  total: undefined,
  status: undefined,
  results: []
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.RESET_STORE:
      return defaultState
    case constants.FETCH_NEWS:
      return reducers.fetchNews(state, action)
    case constants.FETCH_NEWS_NEXT:
      return reducers.fetchNewsNext(state, action)
    case constants.GET_NEWS_ARTICLE:
      return reducers.getNewsArticle(state, action)
    default:
      return state
  }
}
