import * as constants from './constants'
import reducers from './reducers'

export const defaultState = {
  results: [],
  status: undefined,
  count: 0,
  currentDrug: undefined,
  findAllDrugsOnly: false
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.RESET_STORE:
      return defaultState
    case constants.SEARCH_ALL_DRUGS:
      return reducers.searchAllDrugs(state, action)
    case constants.SEARCH_DRUGS:
      return reducers.searchDrugs(state, action)
    case constants.FETCH_DRUG:
      return reducers.fetchDrug(state, action)
    default:
      return state
  }
}
