import { useEffect, useMemo, useState } from "react"
import styled from "styled-components"
import _ from "lodash"
import moment from "moment"
import { v4 as uuid } from "uuid"
import { useDispatch } from "react-redux"
import { useRouter } from "next/router"

import { useLayout } from "../hooks/resourceHooks"
import useTranslate from "../hooks/useTranslate"
import { useNewsArticles, useNews } from "../hooks/newsHooks"
import { useWebsiteConfig } from "../hooks/configHooks"
import { useIsLoggedIn } from "../hooks/authHooks"

import media, { useBreakpoint } from "../config/theme/media"
import { hyperlink, black } from "../config/theme/colors"
import { actions as newsActions } from "../store/modules/news"

import Page from "../containers/Page"
import DrugSearch from "../containers/DrugSearch"
import HeaderLinks from "../containers/HeaderLinks"
import {
  HeaderBlock,
  GridBlock,
  FullWidthBlock,
  HalfWidthBlock,
  HighlightBlock,
  GridContentBlock,
  NewsSummaryBlock,
  ResourceBlock,
} from "../components/Blocks"
import Link from "../components/Link"
import H2 from "../components/Typography/H2"
import H1 from "../components/Typography/H1"
import P from "../components/Typography/P"
import Intro from "../components/Typography/Intro"
import Span from "../components/Typography/Span"
import Button from "../components/Button"
import Loader from "../components/Loader"

const WelcomeTitle = styled(H1)`
  margin: 20px 0;
  ${media.forEach({
    values: { sm: "16", md: "32", lg: "48" },
    getStyle: (val) => `font-size: ${val}px;`,
  })}
`

const IntroText = styled(Intro)`
  margin: 0;
`

const HowToReportText = styled(H2)`
  margin: 20px 0;
`

const ContactNumberContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const ContactNumber = styled(WelcomeTitle)`
  margin: 0;
  ${media.lessThan("md")`
    color: ${({ blue }) => (blue ? hyperlink() : black())};
    &:hover {
      color: ${black()};
    }
  `}
`

const OrText = styled(P)`
  margin: 10px 0 10px 10px;
  font-weight: bold !important;
  letter-spacing: 0;

  ${media.forEach({
    values: { sm: "14", md: "21", lg: "28" },
    getStyle: (val) => `font-size: ${val}px !important;`,
  })}

  ${media.lessThan("md")`
    margin: 5px 0;
  `}
`

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const ReportingHighlightBlock = styled(HighlightBlock)`
  align-items: flex-start;
  ${media.lessThan("md")`
    margin-bottom: 0;
  `}
`

const ReportingBlock = styled(FullWidthBlock)`
  display: block;
  margin-top: 30px;

  ${media.lessThan("md")`
    margin-top: 20px;
  `}
`

const IntroHelpBlock = styled(ResourceBlock)`
  margin: 20px 0 0 28px;
  ${media.lessThan("md")`
    margin: 0;
  `}
`

const Submit = styled(Button)`
  height: auto;
  display: flex;
  justifycontent: center;
  alignitems: center;
  padding: 20px;
`

const Homepage = () => {
  const layout = useLayout()
  const translate = useTranslate()
  const router = useRouter()
  const { lt } = useBreakpoint()
  const dispatch = useDispatch()
  const news = useNews()
  const isLoggedIn = useIsLoggedIn()
  const websiteConfig = useWebsiteConfig()
  const showMedicineLookup = _.get(websiteConfig, "showMedicineLookup")

  const homepageIntroText = _.get(layout, "homepageIntro")
  const homepageIntroSummaryText = _.get(layout, "homepageIntroSummaryText")
  const homepageIntroWelcomeText = _.get(layout, "homepageIntroWelcomeText")
  const homepageIntroLoggedInText = _.get(layout, "homepageIntroLoggedInText")
  const homepageIntroHelpText = _.get(layout, "homepageIntroHelpText")
  const homepageContentBlocks = _.get(layout, "homepageContentBlocks")
  const pinnedNewsArticleId = useMemo(() => _.head(_.get(layout, "pinnedNewsIds")), [
    layout
  ])
  const articles = useNewsArticles()
  const [newsList, setNewsList] = useState(null)
  useEffect(() => {
    dispatch(newsActions.resetStore())
  }, [])
  useEffect(() => {
    dispatch(newsActions.fetchNews({ searchTerm: '' }))
    pinnedNewsArticleId && dispatch(newsActions.getNewsArticle({ id: pinnedNewsArticleId }))
  }, [pinnedNewsArticleId])
  useEffect(() => {
    if (!_.isEmpty(news)) {
      let sortedNews
      const pinnedNews = _.get(articles, [pinnedNewsArticleId])
      const latestNews = _.chain(news).sortBy('publishedDate').reverse().slice(0, 3).value()
      const latestHasPinned = _.find(latestNews, latest => latest.id === _.get(pinnedNews, 'id'))
      if (latestHasPinned) {
        sortedNews = latestNews
      } else {
        sortedNews = _.chain(_.concat(latestNews, pinnedNews)).sortBy('publishedDate').reverse().compact().value()
      }
      setNewsList(sortedNews)
    }
  }, [news, pinnedNewsArticleId, articles])
  const newsComponent = (
    <GridBlock
      key={"Homepage_NewsBlock"}
      titleLinkName={translate("SEE_ALL_NEWS")}
      titleLinkHref={"/news"}
      index={1}
      title={translate("MY_NEWS")}
    >
      {newsList ? (
        _.map(newsList, (article) => {
          const isInternal = _.get(article, "newsFeed.isInternalFeed", true)
          const articleId = _.get(article, "id")
          const title = _.get(article, "title")
          const description = _.get(article, "description")
          const publishDateTime = _.get(article, "publishedDate")
          const updatedDateTime = _.get(article, "updatedAt")
          const externalUrl = _.get(article, "url")
          if (moment(publishDateTime).isAfter(moment())) {
            return null
          }

          return (
            <NewsSummaryBlock
              key={`Homepage_NewsSummaryBlock_${title}`}
              title={title}
              href={isInternal ? `/news/${articleId}` : externalUrl}
              newPageIfExternal={!isInternal}
              description={description}
              publishedAt={publishDateTime}
              updatedAt={updatedDateTime}
            />
          )
        })
      ) : (
        <Loader />
      )}
    </GridBlock>
  )
  const accountAction = (
    <Link href={isLoggedIn ? "/account" : "/sign-in"} noDecoration>
      <Submit bold large type={"submit"}>
        {translate(isLoggedIn ? "MY_ACCOUNT" : "LOGIN")}
      </Submit>
    </Link>
  )

  const reporting = (
    <ReportingBlock stretch>
      <ReportingHighlightBlock>
        {showMedicineLookup ? (
          <DrugSearch searchPrompt={translate("SEARCH_MED_PROMPT")} />
        ) : (
          accountAction
        )}
        {lt("md") && <IntroHelpBlock content={homepageIntroHelpText} />}
      </ReportingHighlightBlock>
      {!lt("md") && <IntroHelpBlock content={homepageIntroHelpText} />}
    </ReportingBlock>
  )

  return (
    <Page>
      <HeaderBlock leftSpacingChild={<HeaderLinks />} isHomepage>
        <FullWidthBlock>
          <WelcomeTitle>{homepageIntroText}</WelcomeTitle>
          <IntroText>{homepageIntroSummaryText}</IntroText>
          <IntroText>{isLoggedIn ? homepageIntroLoggedInText : homepageIntroWelcomeText}</IntroText>
        </FullWidthBlock>
        {/* <HowToReportText>{translate('HOW_TO_REPORT')}</HowToReportText> */}
        {reporting}
      </HeaderBlock>
      <GridContentBlock>
        {_.map(homepageContentBlocks, (contentBlock, index) => {
          const fields = _.get(contentBlock, "fields", {})
          const title = _.get(fields, "blockTitle")
          const content = _.get(fields, "blockDescription")

          // use this when using newsComponent
          const itemIndex = index > 0 ? index + 1 : index

          const contentComponent = (
            <GridBlock
              key={`Homepage_GridBlock_${title}`}
              index={itemIndex}
              title={title}
              content={content}
            />
          )

          if (index === 0) {
            return [contentComponent, newsComponent]
          }

          return contentComponent
        })}
      </GridContentBlock>
    </Page>
  )
}

Homepage.getInitialProps = async ({ store: { dispatch }, ...props }) => {}

export default Homepage
