import { useEffect, useState, Fragment } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { useRouter } from 'next/router'
import { useDispatch } from 'react-redux'
import { v4 as uuid } from 'uuid'

import * as colors from 'config/theme/colors'
import media, { useBreakpoint } from 'config/theme/media'

import { useDrugReportTriggers } from 'hooks/resourceHooks'
import { useShowTerms, useWebsiteConfig } from 'hooks/configHooks'
import useTranslate from 'hooks/useTranslate'
import { useDrugsResults, useIsLoading } from 'hooks/drugsHooks'

import { fetchResources } from 'store/modules/resources/actions'
import newReportActions from 'store/modules/newReport/actions'
import { actions as drugsActions } from 'store/modules/drugs'

import SearchBar from 'components/SearchBar'
import H3 from 'components/Typography/H3'

import Url from 'components/Url'
import { getURLParts } from '../utils/url'

const dontKnow = 'DONT_KNOW'

const Container = styled.div`
  display: block;
  width: 100%;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;
`

const InputContainer = styled.div`
  flex: 1;
`

const LinkContainer = styled.div`
  margin: 10px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${media.lessThan('md')`
    margin: 10px 0 10px 0;
  `}
`

const ErrorText = styled.p`
  color: ${colors.errorTextColor};
  font-size: 12px;
  margin: 5px 0 0 0;
`

const PromptText = styled(H3)`
  display: block;
  margin: 0 0 10px 0;
`

const SearchBlock = ({
  searchPrompt
}) => {
  const router = useRouter()
  const dispatch = useDispatch()
  const translate = useTranslate()
  const { lt } = useBreakpoint()
  const showTerms = useShowTerms()
  const { useProductAPI } = useWebsiteConfig()

  const [submitted, setSubmitted] = useState()
  const [value, setValue] = useState('')
  const [error, setError] = useState()
  const [selectedItem, setSelectedItem] = useState()
  const id = 'product_lookup'
  const errorId = `${id}_error`
  const labelForId = lt('md') ? id + '_mobile' : id
  const isInvalid = submitted && _.isString(error)

  const apiResults = useDrugsResults()
  const contentfulResults = useDrugReportTriggers()
  const itemNameProp = useProductAPI ? 'name' : 'triggerName'

  const items = [...apiResults, ...contentfulResults]
  const loading = useIsLoading()

  const websiteConfig = useWebsiteConfig()
  const minSearchLength = _.get(websiteConfig, 'medicineLookupMinSearchLength', 3)

  useEffect(() => {
    let fetchParams = {}
    if (useProductAPI) {
      fetchParams = {
        where: {
          'fields.category': 'PINNED_LIST'
        }
      }
    }
    dispatch(fetchResources({
      resourceType: 'drugReportTrigger',
      ...fetchParams
    }))
  }, [])

  let searchItems = items.map(item => {
    const name = item.name || item.triggerName
    const section = item.productCategory || item.category || 'Other'
    return {
      ...item,
      id: name,
      label: name,
      section
    }
  }).sort((a, b) => {
    if (a.label === dontKnow) return 1
    else if (b.label === dontKnow) return -1
    else if (a.section !== b.section) return a.section.localeCompare(b.section)
    return a.label.localeCompare(b.label)
  })

  const fallbackSuggestions = searchItems.filter(item => item.category === 'PINNED_LIST')
  searchItems = searchItems.filter(item => item.category !== 'PINNED_LIST')

  const handleSubmit = (value) => {
    setSubmitted(true)

    const matchedDrug = selectedItem || _.find(items, item => item[itemNameProp] === value)

    if (!matchedDrug) {
      return setError('SEARCH_MED_INVALID_ENTRY_ERROR')
    } else {
      setError(undefined)
    }

    const { associatedReport, prefillFormData = {}, formViewCategory, formViewSubCategory, substanceType: substancetype } = matchedDrug

    const externalUrl = _.get(associatedReport, 'fields.externalUrl')

    if (associatedReport && externalUrl) {
      const nameAttribute = associatedReport.fields.openInNewWindow ? '_blank' : '_self'
      const urlParts = getURLParts(externalUrl)
      if (window.location.hostname === urlParts.hostname) {
        router.push({
          pathname: urlParts.pathname
        })
      } else {
        window.open(externalUrl, nameAttribute)
      }
    } else {
      const reportId = uuid()

      if (!useProductAPI) {
        dispatch(newReportActions.reset())
        dispatch(newReportActions.updateInitialValues({
          reportId,
          values: { ...prefillFormData, substancetype }
        }))
        dispatch(newReportActions.updateFormConfig({
          reportId,
          values: {
            ...associatedReport.fields
          }
        }))
      }

      if (useProductAPI) {
        let prefillFormData = _.get(selectedItem, 'reportValues')
        if (prefillFormData) {
          dispatch(newReportActions.reset())
          dispatch(newReportActions.updateInitialValues({
            reportId,
            values: { ...prefillFormData, substancetype }
          }))
        }
      }

      const path = useProductAPI ? '/report-guide' : '/new-report'

      const categoryArray = _.map(formViewCategory, (category) => category.toLowerCase())
      const subCategoryArray = _.map(formViewSubCategory, (category) => category.toLowerCase())

      const query = useProductAPI ? {
        reportId: encodeURIComponent(reportId),
        category: encodeURIComponent(categoryArray),
        subCategory: encodeURIComponent(subCategoryArray),
        product: encodeURIComponent(value)
      } : {
        reportId
      }
      if (showTerms) {
        router.push({
          pathname: '/terms',
          query: {
            redirectTo: path,
            ...query
          }
        })
      } else {
        router.push({
          pathname: path,
          query
        }).then(() => window.scrollTo(0, 0))
      }
    }
  }

  const handleChange = (val) => {
    setSelectedItem(undefined)
    setError(undefined)
    if (val === dontKnow) {
      handleSubmit(val)
    } else {
      setValue(val)
    }
  }

  const handleFetch = (val) => {
    if (val.length < minSearchLength && items.length) {
      dispatch(drugsActions.resetStore())
    } else if (val.length > minSearchLength - 1) {
      dispatch(drugsActions.searchDrugs({ query: val }))
    }
  }

  const handleSelect = (suggestion) => {
    const name = _.get(suggestion, 'name') ? 'name' : 'triggerName'
    const matchedDrug = _.find(items, item => item[name] === suggestion[name])
    if (matchedDrug) {
      setSelectedItem(matchedDrug)
      dispatch(drugsActions.resetStore())
    }
  }

  return (
    <Fragment>
      {!!searchPrompt && <PromptText asComponent={'label'} htmlFor={labelForId}>{searchPrompt}</PromptText>}
      <Container>
        <InputContainer>
          <SearchBar
            id={id}
            submitOnEnter
            autoComplete
            value={value}
            placeholder={translate('SEARCH_MED_PLACEHOLDER')}
            items={searchItems}
            onSubmit={handleSubmit}
            onChange={handleChange}
            submitText={lt('md') ? translate('SEARCH_MED_SUBMIT_MOBILE') : translate('SEARCH_MED_SUBMIT')}
            errorId={errorId}
            isInvalid={isInvalid}
            fallbackSuggestions={fallbackSuggestions}
            fallbackSectionTitle={translate('NOT_IN_LIST_HEADER')}
            showLoading={loading}
            searchMode={useProductAPI ? 'server' : 'client'}
            onSuggestionsFetchRequested={handleFetch}
            onSuggestionSelected={handleSelect}
          />
          {isInvalid && (
            <ErrorText id={errorId}><p>{Url({ text: translate('SEARCH_MED_INVALID_ENTRY_ERROR'), url: '/report-guide' })}</p></ErrorText>
          )}
        </InputContainer>
        <LinkContainer></LinkContainer>
      </Container>
    </Fragment>
  )
}

export default SearchBlock
