import _ from 'lodash'
import * as constants from './constants'
import * as selectors from './selectors'
import { getCurrentLanguage } from '../../../hooks/useTranslate'
import { baseUrl } from 'services/vigilanceHubService'
import qs from 'qs'
// SEO is important for news, the sdk does not work with SSR (references window)
// using fetch here instead of sdk, no auth required

class NewsActions {
  fetchNews = ({ searchTerm, usersWatchlist = [], size = null }) => ({
    type: constants.FETCH_NEWS,
    searchTerm,
    promise: (dispatch, getState) => {
      const { config } = getState()
      const organisationId = _.get(
        config,
        'websiteConfig.vigilanceHubOrganisationId'
      )
      const newsTag = _.get(config, 'websiteConfig.newsTag', '')
      const pageSize = size || selectors.getSize(getState())
      const page = selectors.getPage(getState())
      const language = getCurrentLanguage() || null
      let params = {
        organisationId,
        page,
        size: pageSize,
        language,
        tags: newsTag
      }
      if (usersWatchlist.length > 0) {
        params.usersWatchlist = JSON.stringify(usersWatchlist)
      } 
      if (searchTerm !== '' ) {
        params.searchTerm = searchTerm
      } 
      return fetch(`${baseUrl}/v1/news${qs.stringify(params, { addQueryPrefix: true })}`).then((res) =>
        res.json()
      )
    },
  })

  fetchNewsNext = ({ searchTerm, usersWatchlist = []  }) => ({
    type: constants.FETCH_NEWS_NEXT,
    searchTerm,
    promise: (dispatch, getState) => {
      const { config } = getState()
      const organisationId = _.get(
        config,
        'websiteConfig.vigilanceHubOrganisationId'
      )
      const newsTag = _.get(config, 'websiteConfig.newsTag', '')
      const size = selectors.getSize(getState())
      const page = selectors.getPage(getState())
      const language = getCurrentLanguage() || null
      let params = {
        organisationId,
        page: page + 1,
        size,
        language,
        tags: newsTag
      }
      
      if (usersWatchlist.length > 0) {
        params.usersWatchlist = JSON.stringify(usersWatchlist)
      } 
      
      if (searchTerm !== '' ) {
        params.searchTerm = searchTerm
      } 

      return fetch(`${baseUrl}/v1/news${qs.stringify(params, { addQueryPrefix: true })}`).then((res) =>
        res.json()
      )
    },
  })

  getNewsArticle = ({ id }) => ({
    type: constants.GET_NEWS_ARTICLE,
    payload: { id },
    promise: (dispatch, getState) => {
      return fetch(`${baseUrl}/v1/news/${id}`).then((res) => res.json())
    },
  })

  resetStore = () => ({
    type: constants.RESET_STORE,
  })
}

export default new NewsActions()
