import _ from 'lodash'
import { PENDING, SUCCESS, FAIL } from '../../reduxPromise'

class NewsReducers {
  fetchNews (state, action) {
    switch (action.status) {
      case PENDING:
        return {
          ...state,
          status: PENDING,
          action: action.type
        }
      case SUCCESS:
        const results = _.get(action, 'result.results')
        const total = _.get(action, 'result.total')
        const language = _.get(action, 'result.language')
        return {
          ...state,
          total,
          page: 1,
          language,
          results,
          status: SUCCESS
        }
      case FAIL:
        return {
          ...state,
          status: FAIL,
          error: action.error
        }
      default:
        return state
    }
  }

  fetchNewsNext (state, action) {
    switch (action.status) {
      case PENDING:
        return {
          ...state,
          status: PENDING,
          action: action.type
        }
      case SUCCESS:
        const { results } = state
        const newResults = _.get(action, 'result.results')
        const total = _.get(action, 'result.total') || 0
        const language = _.get(action, 'result.language')
        return {
          ...state,
          total,
          page: state.page + 1,
          language,
          results: [ ...results, ...newResults ],
          status: SUCCESS
        }
      case FAIL:
        return {
          ...state,
          status: FAIL,
          error: action.error
        }
      default:
        return state
    }
  }
  getNewsArticle = (state, action) => {
    switch (action.status) {
      case PENDING:
        return {
          ...state,
          status: PENDING,
          action: action.type
        }
      case SUCCESS:
        return {
          ...state,
          articles: {
            ...state.articles,
            [action.payload.id]: action.result
          },
          status: SUCCESS
        }
      case FAIL:
        return {
          ...state,
          status: FAIL,
          error: action.error
        }
      default:
        return state
    }
  }

}

export default new NewsReducers()
