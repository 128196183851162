import * as constants from './constants'

class NewReportActions {
  reset = () => ({
    type: constants.RESET
  })
  updateInitialValues = ({ reportId, values }) => ({
    type: constants.UPDATE_INITIAL_VALUES,
    values,
    reportId
  })
  updateFormConfig = ({ reportId, values }) => ({
    type: constants.UPDATE_FORM_CONFIG,
    values,
    reportId
  })
  updateUserDetails = ({ reportId, values }) => ({
    type: constants.UPDATE_USER_DETAILS,
    values,
    reportId
  })
  resetInitialValues = () => ({
    type: constants.RESET_INITIAL_VALUES
  })
}

export default new NewReportActions()
