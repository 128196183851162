import { useState } from 'react'
import styled from 'styled-components'
import _ from 'lodash'

import { useLayout } from '../hooks/resourceHooks'
import useTranslate from '../hooks/useTranslate'
import media, { useBreakpoint } from '../config/theme/media'
import { white, grey, selectedBackground } from '../config/theme/colors'

import {
  FullWidthBlock
} from '../components/Blocks'
import Link from '../components/Link'
import Button from '../components/Button'

const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const HeaderLink = styled(Link)`
  font-weight: bold;
  margin-bottom: 33px;
  ${media.forEach({
    values: { sm: '12', md: '15', lg: '18' },
    getStyle: (val) => `font-size: ${val}px;`
  })}

  ${media.lessThan('md')`
    font-weight: normal;
    margin: 10px;
  `}
`

const QuickLinksButton = styled(Button)`
  ${({ open }) => open ? `background-color: ${selectedBackground};` : ''}
  &:hover {
    ${({ open }) => open ? `background-color: ${selectedBackground};` : ''}
  }
`

const QuickLinksOuter = styled.div`
  position: absolute;
  margin-top: 30px;
  background-color: ${white()};
  box-shadow: 0 1px 0 0 rgba(0,0,0,0.2);
  width: calc(100% - 60px);
  padding: 10px;
  z-index: 999;
`

const QuickLinksInner = styled.div`
  display: flex;
  flex-direction: column;
`

const HeaderLinks = () => {
  const layout = useLayout()
  const translate = useTranslate()
  const { lt } = useBreakpoint()
  const [open, setOpen] = useState(false)

  const headerLinks = _.get(layout, 'headerLinks')
  const links = _.map(headerLinks, (link) => {
    const fields = _.get(link, 'fields', {})
    const publishOnWebsite = _.get(fields, 'publishOnWebsite')
    const contentTitle = _.get(fields, 'contentTitle')
    const pageUrlName = _.get(fields, 'pageUrlName')
    const externalUrl = _.get(fields, 'externalUrl')
    if (!publishOnWebsite) {
      return null
    }
    return (
      <HeaderLink
        href={externalUrl || `/${pageUrlName}`}
        key={`HeaderLink_${pageUrlName}`}
      >
        {contentTitle}
      </HeaderLink>
    )
  })

  const canDisplayQuickLinks = links.length > 0

  if (lt('md')) {
    const contentId = 'quick-links-contents'

    return ( canDisplayQuickLinks &&
      <FullWidthBlock>
        <QuickLinksButton
          open={open}
          inverse
          fullWidth
          aria-expanded={open}
          aria-controls={contentId}
          onClick={() => setOpen(!open)}
        >
          {translate('QUICK_LINKS')}
        </QuickLinksButton>
        {
          open && (
            <QuickLinksOuter id={contentId}>
              <QuickLinksInner>
                {links }
              </QuickLinksInner>
            </QuickLinksOuter>
          )
        }
      </FullWidthBlock>
    )
  }

  return ( canDisplayQuickLinks &&
    <LinksContainer>
      {links}
    </LinksContainer>
  )
}

export default HeaderLinks
