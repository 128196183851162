export function getURLParts (url) {
  // only execute on the client side
  if (typeof window !== 'undefined') {
    const temptag = document.createElement('a')
    temptag.href = url
    return temptag
  } else {
    return {}
  }
}
