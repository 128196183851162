// please refer to https://redux-resource.js.org/ for more
// information about how we're using resources in redux.
import _ from 'lodash'

const resourceTypes = [
  'layout',
  'page',
  'announcement',
  'drugReportTrigger',
  'newsResources',
  'faqCategory',
  'faqs',
  'resourcesCategory',
  'resources',
  'dashboard',
  'watchlistProduct',
  'reportMainPage'
]

export const resourceTypesHashmap = _.keyBy(resourceTypes, (x) => x)

export default resourceTypes
