import { useSelector } from 'react-redux'

import { getResults, getHasMore, getIsLoading, getArticleById, getSelectedArticles, getIsFetchMore } from '../store/modules/news/selectors'

/**
 * @description returns news articles
 * @returns
 */
export const useNews = () => {
  return useSelector(getResults)
}

/**
 * @description returns if there are more news articles to fetch
 * @returns
 */
export const useHasMore = () => {
  return useSelector(getHasMore)
}

/**
 * @description returns if the store is currently loading news
 * @returns
 */
export const useIsLoading = () => {
  return useSelector(getIsLoading)
}

/**
 * @description returns if the store is currently loading more news
 * @returns
 */
export const useIsFetchMore = () => {
  return useSelector(getIsFetchMore)
}

/**
 * @description returns selected news article
 * @returns
 */
export const useNewsArticle = ({ id }) => {
  return useSelector(getArticleById({ id }))
}

/**
 * @description returns all selected news articles
 * @returns
 */
export const useNewsArticles = () => {
  return useSelector(getSelectedArticles)
}
