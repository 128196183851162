import { createSelector } from 'reselect'

import { PENDING } from '../../reduxPromise'
import { FETCH_NEWS_NEXT } from './constants'

const getStatus = state => state.news.status
const getAction = state => state.news.action
export const getResults = state => state.news.results
export const getTotal = state => state.news.total
export const getError = state => state.news.error
export const getSize = state => state.news.size
export const getPage = state => state.news.page
export const getLanguage = state => state.news.language
export const getSelectedArticles = state => state.news.articles

export const getIsLoading = createSelector(
  getStatus,
  status => status === PENDING
)

export const getIsFetchMore = createSelector([
  getAction, getStatus
], (action, status) => status === PENDING && action === FETCH_NEWS_NEXT)

export const getHasMore = createSelector([
  getTotal, getResults
], (total, results) => total !== null && results.length < total
)

export const getArticleById = ({ id }) => createSelector([
  getSelectedArticles
], (articles) => {
  return articles[id]
})
