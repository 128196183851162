import { actions as newsActions } from '../reports'
import { constants as authConstants } from '../auth'
import { PENDING, SUCCESS, FAIL } from '../../reduxPromise'

class NewsMiddleware {
  resetInitialValuesOnLogout = ({ dispatch, getState }) => next => (action) => {
    next(action)

    if (action.status === SUCCESS && action.type === authConstants.LOGOUT) {
      dispatch(newsActions.resetStore())
    }
  }
}

export default new NewsMiddleware()
