import styled from 'styled-components'

import { white, black } from '../../config/theme/colors'
import media from '../../config/theme/media'

const StyledH1 = styled.h1`
  color: ${({ inverse }) => inverse ? white() : black()};
  font-weight: bold;
  letter-spacing: 0;
  width: 100%;

  ${media.forEach({
    values: { sm: '16', md: '32', lg: '48' },
    getStyle: (val) => `font-size: ${val}px;`
  })}
`

const H1 = ({ children, ...props }) => {
  return (
    <StyledH1 {...props}>{children}</StyledH1>
  )
}

export default H1
